const ENROLLMENT_STATES = {
  SEARCH_ENROLLMENT: 'SEARCH_ENROLLMENT',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  ERROR: 'ERROR',
  NEEDS_RESIDENTIAL_INFO: 'NEEDS_RESIDENTIAL_INFO',
  NEEDS_OTP_VERIFICATION: 'NEEDS_OTP_VERIFICATION',
  NEEDS_EMAIL_VERIFICATION: 'NEEDS_EMAIL_VERIFICATION',
  THANK_YOU: 'THANK_YOU',
}
const ENROLLMENT_STATUS_REASONS = {
  CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  EMAIL_FAILED: 'EMAIL_FAILED',
  EMAIL_INELIGIBLE: 'EMAIL_INELIGIBLE',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  OTP_FAILED: 'OTP_FAILED',
  OTP_VERIFIED: 'OTP_VERIFIED',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
}

const EMAIL_VERIFICATION_STATUS = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  PENDING: 'PENDING',
}

const MFA_STATUS = {
  BYPASS: 'BYPASS',
  ALLOW: 'ALLOW',
  DENY: 'DENY',
  OTP_PHONE: 'OTP_PHONE',
  OTP_EMAIL: 'OTP_EMAIL',
  OTP_PHONE_EMAIL: 'OTP_PHONE_EMAIL',
}

const CARD_PAYMENT_STATE = {
  INITIAL_ALERT: 'INITIAL_ALERT',
  INITIAL_FORM: 'INITIAL_FORM',
  PAYMENT_DESCRIPTION: 'PAYMENT_DESCRIPTION',
  ADD_PAYLIANCE_CARD: 'ADD_PAYLIANCE_CARD',
  CONFIRM_DETAILS: 'PAYMENT_CONFIRMATION',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PAYMENT_SUCCESSUL: 'PAYMENT_SUCCESSUL',
  PROCESS_PAYMENT: 'PROCESS_PAYMENT',
}

const SUBMISSION_FLOW_TYPE = {
  SAGA_APPLICATION_ONLINE: 'SAGA_APPLICATION_ONLINE',
  SAGA_APPLICATION_ONLINE_LEGACY: 'SAGA_APPLICATION_ONLINE_LEGACY',
}

const FUNDING_TYPE = {
  ACH: 'ACH'
}

const MO = 'MO'

export default {
  ENROLLMENT_STATES,
  ENROLLMENT_STATUS_REASONS,
  EMAIL_VERIFICATION_STATUS,
  MFA_STATUS,
  CARD_PAYMENT_STATE,
  SUBMISSION_FLOW_TYPE,
}

export {
  ENROLLMENT_STATES,
  ENROLLMENT_STATUS_REASONS,
  EMAIL_VERIFICATION_STATUS,
  MFA_STATUS,
  CARD_PAYMENT_STATE,
  SUBMISSION_FLOW_TYPE,
  FUNDING_TYPE,
  MO
}

export const QFundReleaseFF = 'q-fund-release'
